import axios from "axios";
import store from "@/store/index";

function createTurma(payload) {
  const { agenciaId, agenciaNome } = store.getters["gestao/getAgencia"];
  return {
    agenciaId: agenciaId,
    agenciaNome: agenciaNome,
    ano: payload.ano ? payload.ano : null,
    cidadeId: payload.cidadeId ? payload.cidadeId : null,
    cnpj: payload.cnpj ? payload.cnpj : null,
    codigoConvite: payload.codigoConvite ? payload.codigoConvite : null,
    cursos: payload.cursos ? payload.cursos : null,
    dataCadastro: payload.dataCadastro ? payload.dataCadastro : null,
    dataFormatura: payload.dataFormatura ? payload.dataFormatura : null,
    diasRestanteTeste: payload.diasRestanteTeste
      ? payload.diasRestanteTeste
      : null,
    enderecoCompleto: payload.enderecoCompleto
      ? payload.enderecoCompleto
      : null,
    estadoId: payload.estadoId ? payload.estadoId : null,
    id: payload.id ? payload.id : null,
    instituicoes: payload.instituicoes ? payload.instituicoes : null,
    nome: payload.nome ? payload.nome : null,
    nomeNoiva: payload.nomeNoiva ? payload.nomeNoiva : null,
    nomeNoivo: payload.nomeNoivo ? payload.nomeNoivo : null,
    numeroFormando: payload.numeroFormando ? payload.numeroFormando : null,
    pacoteId: payload.pacoteId ? payload.pacoteId : null,
    pacoteTitulo: payload.pacoteTitulo ? payload.pacoteTitulo : null,
    periodo: payload.periodo ? payload.periodo : null,
    semestre: payload.semestre ? payload.semestre : null,
    tipoEvento: payload.tipoEvento ? payload.tipoEvento : null,
    tipoEventoDescricao: payload.tipoEventoDescricao
      ? payload.tipoEventoDescricao
      : null,
    usuarioCriouTurmaId: payload.usuarioCriouTurmaId
      ? payload.usuarioCriouTurmaId
      : null,
    eventoId: payload.eventoId ? payload.eventoId : null,
    eventoNome: payload.eventoNome ? payload.eventoNome : null
  };
}

export default {
  reenviarConvite(email) {
    let turmaID = store.getters["aluno/getTurmaAtiva"];
    return axios.post("/v1/turma/reenviar-convite", {
      turmaId: turmaID,
      email: email
    });
  },
  excluirParticipante(userID) {
    let turmaID = store.getters["aluno/getTurmaAtiva"];
    return axios.delete(`/v1/turma/excluir-convite`, {
      data: {
        turmaId: turmaID,
        usuarioId: userID
      }
    });
  },
  desativaAluno(turmaID, usuarioID) {
    return axios.post("/v1/turma/bloquear-aluno", {
      turmaId: turmaID,
      usuarioId: usuarioID
    });
  },
  aceitaAluno(turmaID, usuarioID) {
    return axios.post("/v1/turma/permitir-aluno", {
      turmaId: turmaID,
      usuarioId: usuarioID
    });
  },
  nomearAlunoAdmin(turmaID, usuarioID) {
    return axios.post("/v1/turma/nomear-aluno-admin", {
      turmaId: turmaID,
      usuarioId: usuarioID
    });
  },
  removerAlunoComissao(turmaID, usuarioID) {
    return axios.post("/v1/turma/remover-aluno-comissao", {
      turmaId: turmaID,
      usuarioId: usuarioID
    });
  },
  addAlunoComissao(turmaID, usuarioID) {
    return axios.post("/v1/turma/add-aluno-comissao", {
      turmaId: turmaID,
      usuarioId: usuarioID
    });
  },
  getParticipantesByEvent(eventId) {
    return axios.get(`/v1/turma/${eventId}/alunos`);
  },
  arquivarTurma(turmaId) {
    return axios.delete(`/v1/turma/${turmaId}/arquivar`);
  },
  getTurmaById(turmaId) {
    return axios.get(`/v1/turma/${turmaId}`);
  },
  novoEvento(payload) {
    let requestBody = createTurma(payload);
    return axios.post("/v1/turma", requestBody);
  },
  alterarEvento(payload) {
    let requestBody = createTurma(payload);
    return axios.post(`/v1/turma/${payload.id}`, requestBody);
  },
  buscarTurma(nomeTurma) {
    return axios.post("/v1/turma/search", {
      texto: nomeTurma
    });
  },
  setSettings(payload) {
    return axios.post("/v1/turma-config", {
      qtdAdmin: payload.qtdAdmin,
      turmaId: payload.turmaId,
      disableComissao: payload.disableComissao,
      enableFCZ: payload.enableFCZ,
      disableJurosMulta: payload.disableJurosMulta,
      peMulta: payload.peMulta,
      peJuros: payload.peJuros
    });
  },
  getSettings(turmaID) {
    return axios.get(`/v1/turma-config/${turmaID}`);
  },
  postDisableNotification(turmaID, flag) {
    return axios.post(`/v1/turma/${turmaID}/disable-notification`, {
      value: flag
    });
  },
  getPlano(turmaID) {
    return axios.get(`/v1/turma/${turmaID}/plano`);
  },
  buscar(payload) {
    return axios.post("/v1/turma/search", { texto: payload });
  },
  getPaginationTurmas(page, pageSize, filtros) {
    return axios.post("/v1/turma/pagination", {
      descending: true,
      filtros: {
        dataFormatura: filtros.dataFormatura,
        nome: filtros.nome,
        codigo: filtros.codigo
        //ano: null,
        //cidadeId: 0,
        //cursoId: 0,
        //estadoId: 0,
        //id: 0,
        //instituicaoId: 0,
        //numeroFormando: 0,
        //semestre: ""
      },
      pageNo: page,
      pageSize: pageSize,
      sortBy: "string"
    });
  }
};
